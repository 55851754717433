import { useEffect, useReducer } from "react"
import { View, Text, StyleSheet, TextInput, Platform } from "react-native"
import Colors from "../constants/Colors"
import { convertDigits } from "./Functions"

const INPUT_CHANGE = "INPUT_CHANGE"
const INPUT_BLUR = "INPUT_BLUR "
const INPUT_FOCUS = "INPUT_FOCUS"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        value: action.value,
      }
    case INPUT_BLUR:
      return {
        ...state,
        focus: false,
        touched: true,
        validity: action.validity,
        error: action.error,
      }
    case INPUT_FOCUS:
      return {
        ...state,
        focus: true,
      }
    default:
      return state
  }
}

const Input = (props) => {
  const { id, onChange, secondId } = props

  const [formState, dispatchFormState] = useReducer(formReducer, {
    focus: false,
    touched: false,
    value: props.value ? props.value : "",
    validity: props.validity ? props.validity : false,
    error: "خطایی رخ داده.",
  })

  useEffect(() => {
    onChange(id, formState.value, formState.validity, secondId)
  }, [id, formState, onChange, secondId])

  const insert = props.insert ? props.insert : false

  useEffect(() => {
    if (insert) {
      dispatchFormState({
        type: INPUT_CHANGE,
        value: insert,
      })
      dispatchFormState({
        type: INPUT_BLUR,
        validity: true,
      })
    }
  }, [insert])

  const validator = (value) => {
    let validity = true
    let error = "خطایی رخ داده."
    if (props.required && value.toString().trim().length === 0) {
      validity = false
      error = props.label + " اجباریست."
    }
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (props.email && !emailRegex.test(value.toLowerCase())) {
      validity = false
      error = "ساختار ایمیل درست نمی باشد."
    }
    if (props.min != null && +value < props.min) {
      validity = false
      error = "حداقل مقدار " + props.min + "می باشد."
    }
    if (props.max != null && +value > props.max) {
      validity = false
      error = "حداکثر مقدار " + props.max + "می باشد."
    }
    if (props.minLength != null && value.length < props.minLength) {
      validity = false
      error = props.label + " حداقل باید " + props.minLength + " کاراکتر باشد."
    }
    if (props.fixedLength != null && value.length != props.fixedLength) {
      validity = false
      error = props.label + " باید " + props.fixedLength + " کاراکتر باشد."
    }
    dispatchFormState({
      type: INPUT_BLUR,
      validity,
      error,
    })
  }

  return (
    <View style={{ width: "100%", ...props.containerStyle }}>
      <View
        style={{
          ...props.wrapperStyle,
          width: "100%",
          height: props.multiline ? 150 : 50,
          borderWidth: 0.8,
          borderRadius: 7,
          borderColor: props.color
            ? props.color
            : formState.focus
            ? Colors.primary
            : !formState.validity && formState.touched
            ? Colors.primary
            : Colors.secondText,
          marginVertical: 15,
        }}
      >
        {props.label && (
          <View
            style={{
              position: "absolute",
              top: -10,
              right: 20,
              backgroundColor: Colors.bg,
              paddingHorizontal: 7,
              ...props.labelWrapperStyle,
            }}
          >
            <Text
              selectable={false}
              style={{
                fontFamily: "IranSans-Light",
                fontSize: 13,
                color: formState.focus
                  ? Colors.primary
                  : !formState.validity && formState.touched
                  ? Colors.primary
                  : Colors.secondText,
              }}
            >
              {props.label}
            </Text>
          </View>
        )}
        <View style={Styles.textInputWrapper}>
          <TextInput
            {...props}
            style={
              Platform.OS == "web"
                ? {
                    width: "100%",
                    height: "100%",
                    outlineStyle: "none",
                    paddingHorizontal: 10,
                    paddingVertical: props.multiline ? 15 : 0,
                    textAlign: props.textAlign ? props.textAlign : "right",
                    direction: props.direction ? props.direction : "rtl",
                    fontFamily: "IranSans-Regular",
                    fontSize: 16,
                    color: Colors.text,
                  }
                : {
                    width: "100%",
                    height: "100%",
                    paddingHorizontal: 10,
                    paddingVertical: props.multiline ? 15 : 0,
                    textAlign: props.textAlign ? props.textAlign : "right",
                    direction: props.direction ? props.direction : "rtl",
                    fontFamily: "IranSans-Regular",
                    fontSize: 16,
                    color: Colors.text,
                  }
            }
            onBlur={(e) => {
              validator(e.nativeEvent.text)
            }}
            onFocus={() => {
              dispatchFormState({ type: INPUT_FOCUS })
            }}
            value={formState.value.toString()}
            onChangeText={(value) => {
              if (props.onlyNumber) {
                let final_value = 0
                if (!isNaN(value)) {
                  final_value = convertDigits("en", value)
                }
                if (props.mobile) {
                  final_value = value.replace(/\D/g, "")
                  final_value = convertDigits("en", final_value)
                  if (final_value.charAt(0) == 0) {
                    final_value = final_value.substring(1)
                  }
                }
                if (props.maxValue) {
                  if (final_value > props.maxValue) {
                    dispatchFormState({
                      type: INPUT_CHANGE,
                      value: props.maxValue,
                    })
                  } else {
                    dispatchFormState({
                      type: INPUT_CHANGE,
                      value: final_value,
                    })
                  }
                } else {
                  dispatchFormState({
                    type: INPUT_CHANGE,
                    value: final_value,
                  })
                }
              } else {
                dispatchFormState({
                  type: INPUT_CHANGE,
                  value: props.convertDigits ? convertDigits("en", value) : value,
                })
              }
            }}
            keyboardType={props.keyboardType}
            maxLength={props.maxLength}
            placeholder={props.placeholder || ""}
            placeholderTextColor={Colors.secondText}
          />
        </View>
      </View>
      {!formState.validity && formState.touched && (
        <View style={Styles.errorContainer}>
          <Text style={Styles.errorText}>{formState.error}</Text>
        </View>
      )}
    </View>
  )
}

const Styles = StyleSheet.create({
  textInputWrapper: {
    width: "100%",
    height: "100%",
  },
  errorContainer: {
    width: "100$",
    height: 50,
    backgroundColor: Colors.error,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 7,
    marginTop: -5,
  },
  errorText: {
    fontFamily: "IranSans-Light",
    color: "#fff",
    fontSize: 12,
  },
})

export default Input
